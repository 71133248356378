//import dependencies
import React, { useState, useRef, useEffect } from "react";
import { motion, useTransform, useMotionValue } from "framer-motion";
import { NetlifyForm, Honeypot } from "react-netlify-forms";
import * as Scroll from "react-scroll";
import { Link as RSLink } from "react-scroll";
import Div100vh from "react-div-100vh";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import ParseHTML from "html-react-parser";

//import components
import Seo from "../components/Seo";
import LampLumpLogo from "../components/LampLumpLogo";
import Bridge from "../components/Bridge";
import Carousel from "../components/Carousel";
import FadeInWhenVisible from "../components/FadeInWhenVisible";

//import styles
import "normalize.css";
import "../scss/index.scss";

//import assets
import ScrollDown from "../images/scroll-down.svg";
import Instagram from "../images/instagram.svg";
import Linkedin from "../images/linkedin.svg";
import IAPMEI from "../images/IAPMEI.svg";
import Logos from "../images/logos.svg";

const scroller = Scroll.scroller;

const scrolldown = {
  hidden: {
    opacity: 1,
    y: 0,
  },
  visible: {
    opacity: 1,
    y: 10,
    transition: {
      type: "tween",
      duration: 1,
      repeat: Infinity,
      repeatType: "reverse",
      delay: 2,
    },
  },
};

console.log(
  "Hello Sherlock. 🕵️‍♂️ \nCongratulations, you found our secret badge!🎖 \nNow that you became an inspector,\nyou can find more at: www.divisa.pt 🍿\nWe hope you enjoy your quest. Bye! 👋"
);

const IndexPage = () => {
  const { t, originalPath, language } = useI18next();

  const x1 = useMotionValue(0);
  const x2 = useMotionValue(0);
  const x3 = useMotionValue(0);
  const x4 = useMotionValue(0);
  const r1 = useTransform(x1, [0, 0], [180, 180]);
  const r2 = useTransform(x2, [0, 0], [-120, -120]);
  const r3 = useTransform(x3, [0, 0], [-30, -30]);
  const r4 = useTransform(x4, [0, 0], [90, 90]);

  const handleScrolldown = () => {
    scroller.scrollTo("mission", {
      smooth: true,
    });
  };

  const sectionEl = useRef();
  const [logoColor, setLogoColor] = useState();

  const changeNavbarColor = () => {
    if (!sectionEl.current) return;
    if (sectionEl.current.getBoundingClientRect().top <= 30) {
      setLogoColor("#fff");
    } else {
      setLogoColor("#8053ff");
    }
  };

  const missionEl = useRef();
  const [active, setActive] = useState(true);

  const changeMissionActive = () => {
    if (!sectionEl.current) return;
    if (missionEl.current.getBoundingClientRect().top <= -140) {
      setActive(false);
    } else {
      setActive(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    window.addEventListener("scroll", changeMissionActive);
  });

  return (
    <>
      <Seo
        description={
          language === "en"
            ? "A digital hub that stimulates cooperation between entrepreneurs, investors, and key people in the startup ecosystem."
            : "A LampLump é uma plataforma que reúne todos os recursos essenciais para qualquer empreendedor desejoso de desenvolver uma startup ou empresa de sucesso."
        }
      />

      <Div100vh>
        <section className="hero" name="hero">
          <Bridge
            drag
            color={"#8BF5CF"}
            style={{
              x: x1,
              rotate: r1,
            }}
            class="hero__bridge1"
          />
          <Bridge
            drag
            color={"#FF8D6D"}
            style={{
              x: x2,
              rotate: r2,
            }}
            class="hero__bridge2"
          />
          <Bridge
            drag
            color={"#8BF5CF"}
            style={{
              x: x3,
              rotate: r3,
            }}
            class="hero__bridge3"
          />
          <Bridge
            drag
            color={"#FF8D6D"}
            style={{
              x: x4,
              rotate: r4,
            }}
            class="hero__bridge4"
          />

          <div className="hero__logo">
            <FadeInWhenVisible delay={0.2}>
              <LampLumpLogo color="#fff" n={1} />
            </FadeInWhenVisible>
          </div>

          <div className="hero__content">
            <FadeInWhenVisible delay={0.2}>
              <p className="hero__content__title">
                {/* The Wait <br /> is Over */}
                {ParseHTML(t("hero.title"))}
              </p>
            </FadeInWhenVisible>
            <FadeInWhenVisible delay={1} style={{ zIndex: 10 }}>
              <h3 className="hero__content__description"> {ParseHTML(t("hero.description"))}</h3>
            </FadeInWhenVisible>
          </div>
          <div className="hero__scroll" onClick={handleScrolldown}>
            <FadeInWhenVisible delay={1.8}>
              <motion.img
                className="hero__scroll__svg"
                src={ScrollDown}
                alt="Scroll Down"
                style={{ marginBottom: "20px" }}
                variants={scrolldown}
                initial="hidden"
                animate="visible"
              />
              <div>{ParseHTML(t("hero.scroll"))}</div>
            </FadeInWhenVisible>
          </div>
        </section>
      </Div100vh>

      <div className="content-wrapper" ref={missionEl}>
        <FadeInWhenVisible delay={0.2} className="navbar">
          <RSLink to="hero" smooth={true} duration={800} spy={true}>
            <LampLumpLogo color={logoColor} n={2} cursor />
          </RSLink>

          <nav className="nav">
            <RSLink
              to="mission"
              smooth={true}
              duration={800}
              spy={true}
              className={`nav__link ${active ? "nav__link__active" : ""}`}
              activeClass="nav__link__active"
            >
              {ParseHTML(t("navbar.mission"))}
            </RSLink>

            <RSLink
              to="what-do-you-get"
              smooth={true}
              duration={800}
              spy={true}
              className="nav__link"
              activeClass="nav__link__active"
            >
              {ParseHTML(t("navbar.what-you-get"))}
            </RSLink>

            <RSLink
              to="stage-of-development"
              smooth={true}
              duration={800}
              spy={true}
              className="nav__link"
              activeClass="nav__link__active"
            >
              {ParseHTML(t("navbar.stage-of-development"))}
            </RSLink>

            <RSLink
              to="about"
              smooth={true}
              duration={800}
              spy={true}
              className="nav__link"
              activeClass="nav__link__active"
            >
              {ParseHTML(t("navbar.about"))}
            </RSLink>
          </nav>
        </FadeInWhenVisible>
        <section className="mission" name="mission">
          <div className="mission__animation">
            <Bridge
              color={"#FF8D6D"}
              // style={{
              //   width: "44.791666666666664vw",
              // }}
              class="mission__animation__bridge"
            />

            <div className="mission__animation__slogan">
              <FadeInWhenVisible delay={0.2}>
                <h1>Building</h1>
              </FadeInWhenVisible>
              <FadeInWhenVisible delay={0.4}>
                <div style={{ transform: "rotate(-20deg)" }}>
                  <motion.div
                    whileTap={{
                      rotate: 340,
                    }}
                    transition={{
                      duration: 1,
                      ease: "easeInOut",
                      // loop: Infinity,
                      // repeatDelay: 1,
                    }}
                    id="bg"
                  >
                    bridges
                  </motion.div>
                </div>
              </FadeInWhenVisible>
              <FadeInWhenVisible delay={0.6}>
                <h1>to success</h1>
              </FadeInWhenVisible>
            </div>
            <FadeInWhenVisible delay={1}>
              <h3 className="mission__animation__description">{ParseHTML(t("mission"))}</h3>
            </FadeInWhenVisible>
          </div>
        </section>

        <section className="what-do-you-get" name="what-do-you-get">
          <Bridge color={"#FF8D6D"} class="what-do-you-get__bridge" />
          <div className="what-do-you-get__title">
            <FadeInWhenVisible>
              <h1>{ParseHTML(t("what-you-get.title"))}</h1>
            </FadeInWhenVisible>
          </div>
          <div className="what-do-you-get__description">
            <FadeInWhenVisible delay={0.4}>
              <p>{ParseHTML(t("what-you-get.p1"))}</p>
              <p>{ParseHTML(t("what-you-get.p2"))}</p>
              <br />
              <p>{ParseHTML(t("what-you-get.p3"))}</p>
              <br />
              <p>{ParseHTML(t("what-you-get.p4"))}</p>
            </FadeInWhenVisible>
          </div>
        </section>

        <section
          className="stage-of-development"
          name="stage-of-development"
          id="stage-of-development"
          ref={sectionEl}
        >
          <div className="flex">
            <Bridge color={"#8BF5CF"} class="stage-of-development__bridge" />

            <div className="stage-of-development__title">
              <FadeInWhenVisible style={{ zIndex: 10 }}>
                <h1>{ParseHTML(t("stage-of-development.title"))}</h1>
              </FadeInWhenVisible>
            </div>

            <div className="stage-of-development__description">
              <FadeInWhenVisible delay={0.4}>
                <p>{ParseHTML(t("stage-of-development.p1"))}</p>
                <p>{ParseHTML(t("stage-of-development.p2"))}</p>
                <br />
                <p>{ParseHTML(t("stage-of-development.p3"))}</p>
                <br />
                <p>{ParseHTML(t("stage-of-development.p4"))}</p>
              </FadeInWhenVisible>
            </div>
          </div>
          <Carousel />
        </section>

        <section className="about" name="about">
          <div className="about__circle" />
          <FadeInWhenVisible>
            <div className="about__title">
              <h1>{ParseHTML(t("about.title"))}</h1>
            </div>
          </FadeInWhenVisible>

          <FadeInWhenVisible delay={0.4}>
            <div className="about__contact">
              <div className="about__contact__form">
                <NetlifyForm name="Contact Form" honeypotName="bot-field">
                  {({ handleChange, success, error }) => (
                    <>
                      <Honeypot />

                      <textarea
                        type="text"
                        name="message"
                        id="message"
                        placeholder={ParseHTML(t("about.form.text-area"))}
                        onChange={handleChange}
                        required
                      />

                      <div className="input-submit">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder={ParseHTML(t("about.form.email"))}
                          onChange={handleChange}
                          required
                        />

                        <button type="submit" className="submit">
                          {ParseHTML(t("about.form.submit"))}
                        </button>
                      </div>

                      {success && <p className="success">{ParseHTML(t("about.form.success"))}</p>}
                      {error && <p className="error">{ParseHTML(t("about.form.error"))}</p>}
                    </>
                  )}
                </NetlifyForm>
              </div>
              <div className="about__contact__text">
                <p>{ParseHTML(t("about.p1"))}</p>
                <br />
                <p>{ParseHTML(t("about.p2"))}</p>
                <br />
                <p>{ParseHTML(t("about.p3"))}</p>
              </div>
            </div>
          </FadeInWhenVisible>
          <div className="about__logos">
            <div>
              <p>{ParseHTML(t("about.follow"))}</p>
              <div className="social-logos">
                <a
                  href="https://www.instagram.com/lamplumpsocial/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Instagram} alt="Instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/lamplumpsocial/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Linkedin} alt="LinkedIn" />
                </a>
              </div>
            </div>
            <div className="suports">
              <div>
                <p style={{ marginTop: "-15px" }}>{ParseHTML(t("about.project"))}</p>
                <img src={IAPMEI} alt="IAPMEI" id="IAPMEI" />
              </div>
              <div>
                <p>{ParseHTML(t("about.finance"))}</p>
                <img src={Logos} alt="Logos" id="logos" />
              </div>
            </div>

            <div className="about__rights">
              {language === "pt" ? (
                <Link to={originalPath} language="en" id="lang">
                  <p>English, please!</p>
                </Link>
              ) : (
                <Link to={originalPath} language="pt" id="lang">
                  <p>Português, por favor!</p>
                </Link>
              )}

              <p>© 2021 LampLump</p>

              <p>
                Crafted by{" "}
                <a href="http://divisa.pt" target="_blank" rel="noopener noreferrer">
                  Divisa.
                </a>
              </p>
            </div>
          </div>
          {/* <div className="about__rights">
            {language === "pt" ? (
              <Link to={originalPath} language="en">
                <p>English, please!</p>
              </Link>
            ) : (
              <Link to={originalPath} language="pt">
                <p>Português, por favor!</p>
              </Link>
            )}

            <p>© 2021 LampLump</p>

            <p>
              Crafted by{" "}
              <a href="http://divisa.pt" target="_blank" rel="noopener noreferrer">
                Divisa.
              </a>
            </p>
          </div> */}
        </section>
      </div>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
