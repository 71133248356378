//import dependencies
import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const Seo = ({ title, description, path, image }) => {
  const { language } = useI18next();

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.siteUrl === "" && typeof window !== "undefined") {
    defaults.siteUrl = window.location.origin;
  }

  if (defaults.siteUrl === "") {
    console.error("Please set a siteUrl in your site metadata!");
    return null;
  }

  const metaLang = language === "pt" ? "pt-PT" : "en-US";
  const metaTitle = title || defaults.title;
  const metaDescription = description || defaults.description;
  const metaUrl = new URL(path || "", defaults.siteUrl);
  const metaImage = image ? new URL(image, defaults.siteUrl) : false;

  return (
    <Helmet>
      <html lang={metaLang} />
      <title>{metaTitle}</title>
      <link rel="canonical" href={metaUrl} />
      <meta name="description" content={metaDescription} />
      {image && <meta name="image" content={metaImage} />}

      <meta property="og:url" content={metaUrl} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      {image && <meta property="og:image" content={metaImage} />}

      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name='twitter:creator' content={post.author.twitter} /> */}
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {image && <meta name="twitter:image" content={metaImage} />}
    </Helmet>
  );
};

export default Seo;
