//import dependencies
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Bridge(props) {
  const bridgeVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 858.62 429.31"
      drag={props.drag}
      dragConstraints={{
        top: -20,
        left: -20,
        right: 20,
        bottom: 20,
      }}
      style={props.style}
      className={props.class}
    >
      <defs>
        <linearGradient
          id={`gradient${props.class}`}
          x1={429.31}
          y1={429.31}
          x2={429.31}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor={props.color} stopOpacity={0} />
          <stop offset={1} stopColor={props.color} />
        </linearGradient>
      </defs>

      <motion.path
        d="M58 429.31C58 225.62 225.62 58 429.31 58s371.31 167.62 371.31 371.31"
        fill="none"
        strokeMiterlimit={10}
        strokeWidth={116}
        stroke={`url(#gradient${props.class})`}
        // animate={props.controls}
        animate={controls}
        variants={bridgeVariants}
        initial="hidden"
      />
    </motion.svg>
  );
}
