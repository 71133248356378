//import dependencies
import React, { useState, useEffect } from "react";
import { useDrag } from "react-use-gesture";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import ParseHTML from "html-react-parser";

export default function Carousel() {
  const { t } = useI18next();

  const carouselVariants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  const [currentSlider, setCurrentSlider] = useState(1);

  const nextSlider = () => {
    if (currentSlider === 4) setCurrentSlider(1);
    else currentSlider < 4 && setCurrentSlider(currentSlider + 1);
  };
  const previousSlider = () => {
    if (currentSlider === 1) setCurrentSlider(4);
    currentSlider > 1 && setCurrentSlider(currentSlider - 1);
  };

  const bind = useDrag(({ swipe: [swipeX] }) => {
    console.log("swipeX: ", swipeX);
    // position will either be -1, 0 or 1
    if (swipeX > 0) {
      previousSlider();
    }
    if (swipeX < 0) {
      nextSlider();
    }
  });

  useEffect(() => {
    // const next = (currentSlider + 1) % 4;
    if (currentSlider === 5) setCurrentSlider(1);
    const id = setTimeout(() => setCurrentSlider(currentSlider + 1), 4000);
    return () => clearTimeout(id);
  }, [currentSlider]);

  return (
    <div className="carousel">
      <div
        className="carousel__wrapper"
        {...bind()}
        style={{
          touchAction: "pan-y",
        }}
      >
        <div className="info">
          <div className="info__numeration">
            <div
              className={`numeration-index ${currentSlider === 1 ? "active" : ""}`}
              onClick={() => {
                setCurrentSlider(1);
              }}
            >
              <span>
                <p>1</p>
              </span>
            </div>
            <div
              className={`numeration-index ${currentSlider === 2 ? "active" : ""}`}
              onClick={() => {
                setCurrentSlider(2);
              }}
            >
              <span>
                <p>2</p>
              </span>
            </div>
            <div
              className={`numeration-index ${currentSlider === 3 ? "active" : ""}`}
              onClick={() => {
                setCurrentSlider(3);
              }}
            >
              <span>
                <p>3</p>
              </span>
            </div>
            <div
              className={`numeration-index ${currentSlider === 4 ? "active" : ""}`}
              onClick={() => {
                setCurrentSlider(4);
              }}
            >
              <span>
                <p>4</p>
              </span>
            </div>
          </div>
          <div className="info__titles">
            <motion.div
              className="images__item"
              animate={currentSlider === 1 ? "open" : "closed"}
              variants={carouselVariants}
            >
              <h2>{ParseHTML(t("stage-of-development.s1"))}</h2>
            </motion.div>
            <motion.div
              className="images__item"
              animate={currentSlider === 2 ? "open" : "closed"}
              variants={carouselVariants}
            >
              <h2>{ParseHTML(t("stage-of-development.s2"))}</h2>
            </motion.div>
            <motion.div
              className="images__item"
              animate={currentSlider === 3 ? "open" : "closed"}
              variants={carouselVariants}
            >
              <h2>{ParseHTML(t("stage-of-development.s3"))}</h2>
            </motion.div>
            <motion.div
              className="images__item"
              animate={currentSlider === 4 ? "open" : "closed"}
              variants={carouselVariants}
            >
              <h2>{ParseHTML(t("stage-of-development.s4"))}</h2>
            </motion.div>
          </div>
        </div>
        <div className="images" onClick={nextSlider}>
          <div className="magic-trick">
            <h2 style={{ display: currentSlider === 1 ? "block" : "none" }}>
              {ParseHTML(t("stage-of-development.s1"))}
            </h2>
            <h2 style={{ display: currentSlider === 2 ? "block" : "none" }}>
              {ParseHTML(t("stage-of-development.s2"))}
            </h2>
            <h2 style={{ display: currentSlider === 3 ? "block" : "none" }}>
              {ParseHTML(t("stage-of-development.s3"))}
            </h2>
            <h2 style={{ display: currentSlider === 4 ? "block" : "none" }}>
              {ParseHTML(t("stage-of-development.s4"))}
            </h2>
          </div>
          <motion.div
            className="images__item"
            animate={currentSlider === 1 ? "open" : "closed"}
            variants={carouselVariants}
          >
            <StaticImage
              className="carousel-image"
              imgStyle={{ borderRadius: "40px" }}
              src="../images/presentation.jpg"
              alt="Presentation image"
            />
          </motion.div>
          <motion.div
            className="images__item"
            animate={currentSlider === 2 ? "open" : "closed"}
            variants={carouselVariants}
          >
            <StaticImage
              draggable={false}
              className="carousel-image"
              imgStyle={{ borderRadius: "40px" }}
              src="../images/feedback.jpg"
              alt="Feedback image"
            />
          </motion.div>
          <motion.div
            className="images__item"
            animate={currentSlider === 3 ? "open" : "closed"}
            variants={carouselVariants}
          >
            <StaticImage
              draggable={false}
              className="carousel-image"
              imgStyle={{ borderRadius: "40px" }}
              src="../images/pitch.jpg"
              alt="Pitch image"
            />
          </motion.div>
          <motion.div
            className="images__item"
            animate={currentSlider === 4 ? "open" : "closed"}
            variants={carouselVariants}
          >
            <StaticImage
              draggable={false}
              className="carousel-image"
              imgStyle={{ borderRadius: "40px" }}
              src="../images/connections.jpg"
              alt="Connections image"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
