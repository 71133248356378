//import dependencies
import React from "react";
import { motion } from "framer-motion";

export default function LampLumpLogo(props) {
  const bridgeVariants = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        ease: "easeInOut",
        duration: 2,
      },
    },
  };

  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 272.02 71.43"
      width="272px"
      cursor={props.cursor ? "pointer" : "default"}
      style={{ filter: "drop-shadow(0px 0px 5px #00000029)" }}
    >
      <defs>
        <style>
          {`.prefix__cls-1${props.n}{fill:${props.color}}.prefix__cls-2${props.n}{fill:none;stroke:${props.color};stroke-miterlimit:10;stroke-width:7px;}`}
        </style>
      </defs>
      <path
        id="prefix__Path_1"
        data-name="Path 1"
        className={`prefix__cls-1${props.n}`}
        d="M0 234.72v-26.67h7v20.77h14.16v5.9z"
        transform="translate(.02 -175.32)"
      />
      <path
        id="prefix__Path_2"
        data-name="Path 2"
        className={`prefix__cls-1${props.n}`}
        d="M24.65 215.67a13.52 13.52 0 012.65-4.51 11.2 11.2 0 014-2.81 12.37 12.37 0 014.84-1 14.19 14.19 0 012.76.27 8.94 8.94 0 012.35.85 7.5 7.5 0 011.89 1.42 7.89 7.89 0 011.36 1.94h.22v-3.82h6.73v26.67h-6.77v-3.78h-.22a7.13 7.13 0 01-1.46 1.94 8.07 8.07 0 01-2 1.42 9.56 9.56 0 01-2.46.85 15.25 15.25 0 01-2.87.27 12.24 12.24 0 01-4.7-.9 10.9 10.9 0 01-3.82-2.65 12.38 12.38 0 01-2.57-4.35 19 19 0 010-11.81zm6.78 9.49a7.19 7.19 0 001.36 2.65 6.13 6.13 0 002.14 1.64 6.3 6.3 0 002.7.57 6 6 0 002.63-.57 6.33 6.33 0 002.1-1.64 8 8 0 001.42-2.6 11.53 11.53 0 00.57-3.39v-.44a12 12 0 00-.49-3.58 8.2 8.2 0 00-1.39-2.73 6.26 6.26 0 00-2.14-1.72 6 6 0 00-2.7-.6 5.8 5.8 0 00-2.71.63 6.25 6.25 0 00-2.13 1.8 8.35 8.35 0 00-1.37 2.82 13.34 13.34 0 00-.42 3.6 12.27 12.27 0 00.47 3.56z"
        transform="translate(.02 -175.32)"
      />
      <path
        id="prefix__Path_3"
        data-name="Path 3"
        className={`prefix__cls-1${props.n}`}
        d="M57.91 234.72v-26.67h6.73v4.3h.22a7.39 7.39 0 011.39-1.89 8.57 8.57 0 011.89-1.38 8.85 8.85 0 012.24-.82A12.25 12.25 0 0173 208a13.1 13.1 0 012.79.3 8.85 8.85 0 012.43.93 7.35 7.35 0 012 1.6 8.86 8.86 0 011.48 2.33 9.41 9.41 0 011.69-2.42 8.56 8.56 0 012.13-1.6 10 10 0 012.44-.87 13.59 13.59 0 012.7-.27 12.16 12.16 0 013.83.59 8 8 0 015.16 5.16 14.62 14.62 0 01.74 4.95v16h-7v-15.18a10.32 10.32 0 00-.39-2.82 5 5 0 00-.93-1.89 3.43 3.43 0 00-1.48-1 6 6 0 00-2-.32 5.31 5.31 0 00-2.32.51 5.58 5.58 0 00-1.89 1.48 6.8 6.8 0 00-1.25 2.4 10.23 10.23 0 00-.44 3.16v13.71h-7v-15.2a10.32 10.32 0 00-.33-2.82 4.78 4.78 0 00-1-1.89 3.64 3.64 0 00-1.47-1 5.7 5.7 0 00-2-.32 5.35 5.35 0 00-2.33.51 5.56 5.56 0 00-1.88 1.46 6.5 6.5 0 00-1.26 2.36A11 11 0 0065 221v13.77z"
        transform="translate(.02 -175.32)"
      />
      <path
        id="prefix__Path_4"
        data-name="Path 4"
        className={`prefix__cls-1${props.n}`}
        d="M113.56 246.75h-7v-38.7h6.72v3.83h.22a6.19 6.19 0 011.42-1.68 8.41 8.41 0 012-1.23 10.57 10.57 0 012.46-.74 16.26 16.26 0 012.87-.23 13 13 0 014.73.86 10.82 10.82 0 013.85 2.54 11.39 11.39 0 012.55 4.16 16.54 16.54 0 01.9 5.62 16.15 16.15 0 01-1 5.68 12.5 12.5 0 01-2.68 4.32 11.77 11.77 0 01-4 2.69 12.92 12.92 0 01-4.84.91 14.66 14.66 0 01-2.73-.23 9.23 9.23 0 01-2.3-.74 8.08 8.08 0 01-1.89-1.23 6.43 6.43 0 01-1.39-1.68h-.22a74.381 74.381 0 01.3 4.83c.02.76 0 1.41 0 2v9.06zm13-28.83a7.23 7.23 0 00-1.37-2.49 6 6 0 00-2.1-1.54 6.66 6.66 0 00-2.71-.54 6.34 6.34 0 00-4.81 2.06 6.73 6.73 0 00-1.42 2.42 10.86 10.86 0 00-.55 3.2v.35a10.71 10.71 0 00.5 3.33 7.29 7.29 0 001.39 2.55 6.22 6.22 0 002.16 1.6 6.58 6.58 0 002.73.56 6.32 6.32 0 002.71-.58 6.07 6.07 0 002.1-1.66 8.26 8.26 0 001.37-2.61 11.62 11.62 0 000-6.66z"
        transform="translate(.02 -175.32)"
      />
      <path
        id="prefix__Path_5"
        data-name="Path 5"
        className={`prefix__cls-1${props.n}`}
        d="M139.54 234.72v-26.67h7v20.77h14.15v5.9z"
        transform="translate(.02 -175.32)"
      />
      <path
        id="prefix__Path_6"
        data-name="Path 6"
        className={`prefix__cls-1${props.n}`}
        d="M164 208.05h7.05v14.88a10.67 10.67 0 00.36 3 4.81 4.81 0 001 2 4.15 4.15 0 001.69 1.07 6.3 6.3 0 002.16.34 5.42 5.42 0 004.32-2 6.85 6.85 0 001.25-2.39 10.65 10.65 0 00.44-3.17v-13.73h7v26.67h-6.67v-4.3h-.22a7.65 7.65 0 01-3.23 3.27 8.3 8.3 0 01-2.26.83 12 12 0 01-2.6.26 12.63 12.63 0 01-4-.59 8.43 8.43 0 01-3.25-1.88 8.82 8.82 0 01-2.21-3.42 14 14 0 01-.82-5.08z"
        transform="translate(.02 -175.32)"
      />
      <path
        id="prefix__Path_7"
        data-name="Path 7"
        className={`prefix__cls-1${props.n}`}
        d="M195.69 234.72v-26.67h6.72v4.3h.22a7.39 7.39 0 011.39-1.89 8.57 8.57 0 011.89-1.38 9 9 0 012.24-.82 12.4 12.4 0 012.57-.27 13.1 13.1 0 012.79.3 8.85 8.85 0 012.43.93 7.35 7.35 0 012 1.6 8.86 8.86 0 011.48 2.33 9.66 9.66 0 011.69-2.42 8.56 8.56 0 012.13-1.6 10 10 0 012.44-.87 13.59 13.59 0 012.7-.27 12.16 12.16 0 013.83.59 8 8 0 015.17 5.16 14.86 14.86 0 01.73 4.95v16h-7.05v-15.17a10.32 10.32 0 00-.33-2.82 5.06 5.06 0 00-.93-1.89 3.46 3.46 0 00-1.47-1 6.06 6.06 0 00-2-.32 5.35 5.35 0 00-2.33.51 5.55 5.55 0 00-1.88 1.48 6.83 6.83 0 00-1.26 2.4 10.87 10.87 0 00-.44 3.16v13.71h-7v-15.2a10.32 10.32 0 00-.33-2.82 4.89 4.89 0 00-.95-1.89 3.74 3.74 0 00-1.48-1 5.67 5.67 0 00-2-.32 5.34 5.34 0 00-2.32.51 5.5 5.5 0 00-1.89 1.46 6.63 6.63 0 00-1.25 2.36 10.33 10.33 0 00-.44 3.17v13.77z"
        transform="translate(.02 -175.32)"
      />
      <path
        id="prefix__Path_8"
        data-name="Path 8"
        className={`prefix__cls-1${props.n}`}
        d="M251.34 246.75h-7.06v-38.7H251v3.83h.22a6.19 6.19 0 011.42-1.68 8.22 8.22 0 012-1.23 10.43 10.43 0 012.46-.74 16.26 16.26 0 012.9-.23 13 13 0 014.73.86 10.91 10.91 0 013.85 2.54 11.37 11.37 0 012.54 4.16 16.3 16.3 0 01.9 5.62 15.92 15.92 0 01-.95 5.68 12.5 12.5 0 01-2.68 4.32 11.63 11.63 0 01-4 2.69 13 13 0 01-4.84.91 14.84 14.84 0 01-2.74-.23 9.5 9.5 0 01-2.3-.74 8 8 0 01-1.88-1.23 6.46 6.46 0 01-1.4-1.68H251c.07.78.13 1.58.19 2.43s.09 1.64.11 2.4 0 1.41 0 2v9.06zm13-28.83a7.07 7.07 0 00-1.37-2.49 5.89 5.89 0 00-2.09-1.54 6.66 6.66 0 00-2.71-.54 6.31 6.31 0 00-4.81 2.06 6.59 6.59 0 00-1.42 2.42 10.86 10.86 0 00-.55 3.2v.35a10.7 10.7 0 00.49 3.33 7.32 7.32 0 001.4 2.55 6.22 6.22 0 002.16 1.6 6.58 6.58 0 002.73.56 6.28 6.28 0 002.7-.58 5.9 5.9 0 002.1-1.67 7.73 7.73 0 001.37-2.6 11.45 11.45 0 000-6.66z"
        transform="translate(.02 -175.32)"
      />
      <motion.path
        className={`prefix__cls-2${props.n}`}
        d="M143.05 201.05c0-12.19 9.64-22.23 21.36-22.23s21.36 10 21.36 22.23M3.48 201.05a22.29 22.29 0 0144.57 0"
        transform="translate(.02 -175.32)"
        variants={bridgeVariants}
        initial="hidden"
        animate="visible"
      />
    </svg>
  );
}
